.customScrollbar {
  max-height: 160px;
  overflow-y: auto;
  padding-right: 16px;
  margin-bottom: 10px;
}

.customScrollbar::-webkit-scrollbar {
  width: 8px;
}

.customScrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.customScrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 4px;
}

.customScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: grey;
}
